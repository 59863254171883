const createContainerAction = (name, toast, token, advertiser) => new Promise((resolve, reject) => {
  const body = JSON.stringify({
    authenticity_token: token,
    container: { name },
  });
  const method = 'POST';
  const requestOptions = {
    body,
    headers: { 'Content-Type': 'application/json' },
    method,
  };

  fetch(`/vendors/${advertiser.id}/containers`, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      if (data.status) {
        toast.success(data.message);
        resolve(data.result);
      } else {
        throw new Error(data.message || 'An error occurred, please contact support.');
      }
    })
    .catch((error) => {
      toast.error('Error:', error.message);
      reject(error);
    });
});

const getContainersAction = (advertiser) => new Promise((resolve, reject) => {
  const method = 'GET';
  const requestOptions = {
    headers: { 'Content-Type': 'application/json' },
    method,
  };

  fetch(`/vendors/${advertiser.id}/containers`, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      if (data.status) {
        // toast.success(data.message);
        resolve(data.result);
      } else {
        throw new Error(data.message || 'An error occurred, please contact support.');
      }
    })
    .catch((error) => {
      // toast.error('Error:', error.message);
      reject(error);
    });
});

export {
  createContainerAction, getContainersAction,
};
