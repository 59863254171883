import React, { useEffect, useState } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { getContainersAction } from './actions/container';
import ContainersTable from './containers/ContainersTable';
import SlideOutHeader from './SlideOutHeader';
import EmptyContainersMessage from './containers/EmptyContainersMessage';
import AddContainerComponent from './containers/AddContainerComponent';
import ContainerIndex from './containers/ContainerIndex';

const SlideOutComponent = ({
  isPaneOpen, setPaneOpen, onRequestClose, token, advertiser,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [containers, setContainers] = useState([]);
  const [isContainerManager, setIsContainerManager] = useState(false);
  const [isPixelManager, setIsPixelManager] = useState(false);
  const [activeContainer, setActiveContainer] = useState(null);

  const handleContainersChange = (containersData) => {
    if (containersData.length === 0) {
      setIsContainerManager(true);
    } else {
      setIsContainerManager(false);
      setIsPixelManager(true);
      setActiveContainer(containersData[0]);
    }
  };

  useEffect(() => {
    getContainersAction(advertiser).then((data) => {
      setContainers(data);
      handleContainersChange(data);
    });
  }, []);

  useEffect(() => {
    handleContainersChange(containers);
  }, [containers]);

  return (
    <div>
      <SlidingPane
        className="tag-manager-slider"
        overlayClassName="tag-manager-slider-overlay"
        isOpen={isPaneOpen}
        hideHeader
        onRequestClose={onRequestClose}
      >
        {isContainerManager && (
          <>
            <div className="tag-manager">
              <SlideOutHeader
                setIsModalOpen={setIsModalOpen}
                title="Pixel Container"
                subtitle="Add containers and manage all of your pixels in one place."
                containers={containers}
              />
              {containers && containers.length > 0 && (
              <ContainersTable
                containers={containers}
                setIsContainerManager={setIsContainerManager}
                setIsPixelManager={setIsPixelManager}
                setActiveContainer={setActiveContainer}
                activeContainer={activeContainer}
              />
              )}
              {containers && containers.length === 0 && (
              <EmptyContainersMessage setIsModalOpen={setIsModalOpen} />
              )}
            </div>
            {isModalOpen && (
            <AddContainerComponent
              token={token}
              setContainers={setContainers}
              advertiser={advertiser}
              onClose={() => setIsModalOpen(false)}
            />
            )}
          </>
        )}
        {isPixelManager && (
          <ContainerIndex
            setIsContainerManager={setIsContainerManager}
            setIsPixelManager={setIsPixelManager}
            token={token}
            advertiser={advertiser}
            activeContainer={activeContainer}
            setActiveContainer={setActiveContainer}
            containers={containers}
            setPaneOpen={setPaneOpen}
          />
        )}
      </SlidingPane>
    </div>
  );
};

export default SlideOutComponent;
