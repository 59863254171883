import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getPixelsForContainer, createPixelAction } from '../actions/pixel';
import AddPixelModal from '../pixels/AddPixelModal';
import AddTriggersModal from '../pixels/AddTriggersModal';
// import ChevronDownIcon from '../../../images/tag_manager/chevron_down_icon.svg';
import PixelTable from '../pixels/PixelTable';
import ShowContainerModal from './ShowContainerModal';

const ContainersIndex = ({
  token,
  advertiser,
  activeContainer,
  // setActiveContainer,
  setIsContainerManager,
  // setIsPixelManager,
  // containers,
  setPaneOpen,
}) => {
  const fieldTriggerMapping = (field) => {
    switch (field) {
      case 'pageUrl':
        return 'Page URL';
      default:
        return field;
    }
  };

  const [pixels, setPixels] = useState([]);
  const [containerCode, setContainerCode] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const [showAddPixelModal, setShowAddPixelModal] = useState(false);
  const [showAddTriggersModal, setShowAddTriggersModal] = useState(false);
  const [showPixelCodeModal, setShowPixelCodeModal] = useState(false);
  const [addPendingPixelRow, setAddPendingPixelRow] = useState(false);
  const [pendingPixel, setPendingPixel] = useState({
    code: '',
    container_id: '',
    name: '',
  });

  const [pendingTrigger, setPendingTrigger] = useState({
    container_id: '',
    json: '',
  });

  const onBackClick = () => {
    setPaneOpen(false);
    setIsContainerManager(false);
  };

  const handleRemovePendingPixel = () => {
    setPendingPixel({
      code: '',
      container_id: '',
      name: '',
    });
    setPendingTrigger({
      container_id: '',
      json: '',
    });
  };

  useEffect(() => {
    getPixelsForContainer(advertiser, activeContainer).then((data) => {
      setPixels(data);
    });
  }, [activeContainer]);

  const onAddPixelClick = () => {
    setShowAddPixelModal(true);
  };

  const onAddTriggersClick = () => {
    if (!pendingPixel.name) {
      toast.error('Please Place a DSP Pixel first.');
    } else {
      setShowAddTriggersModal(true);
    }
  };

  useEffect(() => {
    setContainerCode(`<script>
    (function(w,d,s){var i=new URLSearchParams(w.location.search).has('debug'),
    f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async=!0;
    j.src='https://orion-pixel.s3-accelerate.amazonaws.com/${process.env.NODE_ENV[0]}/${advertiser.id}-${activeContainer.id}.js';j.onload=function(){i&&alert('Loaded');};
    f.parentNode.insertBefore(j,f);})(window,document,'script');
</script>`);
  }, [activeContainer]);

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(text);
    }
    return document.execCommand('copy', true, text);
  }

  // onClick handler function for the copy button
  const handleCreatedCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(containerCode)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        toast.error('Failed to copy!', err);
      });
  };

  // const handleContainerSelect = (container) => {
  //   setActiveContainer(container);
  // };

  useEffect(() => {
    async function createPixelAndTrigger() {
      // Check if all necessary fields in both pendingPixel and pendingTrigger are filled
      if (pendingPixel.code && pendingPixel.container_id && pendingPixel.name
        && pendingTrigger.container_id && pendingTrigger.json) {
        try {
          const response = await createPixelAction(pendingPixel,
            pendingTrigger,
            activeContainer,
            token,
            advertiser);

          if (response.status) {
            toast.success('Pixel and Trigger created successfully');
            setPixels((prevPixels) => [...prevPixels, response.result]);
            setAddPendingPixelRow(false);
            // Reset pendingPixel and pendingTrigger
            handleRemovePendingPixel();
          } else {
            // Handle failure scenario
            toast.error(response.message || 'An error occurred while creating the Pixel and Trigger.');
          }
        } catch (error) {
          // Handle unexpected errors, like network issues
          toast.error('An unexpected error occurred. Please try again.');
        }
      }
    }

    createPixelAndTrigger();
  }, [pendingPixel, pendingTrigger]);

  return (
    <div className="pixel-manager">
      <div className="row mb-2">
        <div className="col-6 d-flex align-items-center">
          {/* <div
            className="back-button mr-2"
            onClick={() => onBackClick()}
          >
            <i className="fa fa-arrow-left" />
          </div> */}
          <div>
            <div className="heading">
              Pixel Container
            </div>
            <div className="title">
              {activeContainer.name}
            </div>
          </div>
        </div>
        <div
          tabIndex="0"
          role="button"
          onKeyDown={(e) => {
            if (e.keyCode === 13 || e.keyCode === 32) { // 13 is Enter, 32 is Space
              setShowPixelCodeModal(true);
            }
          }}
          className="col-6 text-right d-flex justify-content-end align-items-end mb-2"
          onClick={() => setShowPixelCodeModal(true)}
        >
          <div className="text-xs-semibold black">Container ID:</div>
          &nbsp;
          <div className="text-xs-semibold purple">
            ORC-
            {activeContainer.id}
          </div>
          {/* <Dropdown>
            <Dropdown.Toggle as="div"
            id="dropdown-custom-components pointer" className="hideToggle">
              <img src={ChevronDownIcon} alt="Chevron Down" className="ml-2 h-17" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {containers.map((container) => (
                <Dropdown.Item
                  key={container.id}
                  onClick={() => handleContainerSelect(container)}
                  style={{ color: activeContainer.id === container.id ? '#6E20A1' : 'inherit' }}
                >
                  {container.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown> */}
        </div>

      </div>
      { showPixelCodeModal && (
        <ShowContainerModal
          container={activeContainer}
          setShowPixelCodeModal={setShowPixelCodeModal}
          pixels={pixels}
          containerCode={containerCode}
          isCopied={isCopied}
          handleCreatedCopyClick={handleCreatedCopyClick}
        />
      )}
      <PixelTable
        advertiser={advertiser}
        token={token}
        pixels={pixels}
        setPixels={setPixels}
        fieldTriggerMapping={fieldTriggerMapping}
        onAddPixelClick={onAddPixelClick}
        onAddTriggersClick={onAddTriggersClick}
        pendingPixel={pendingPixel}
        activeContainer={activeContainer}
        addPendingPixelRow={addPendingPixelRow}
        setAddPendingPixelRow={setAddPendingPixelRow}
        handleRemovePendingPixel={handleRemovePendingPixel}
      />
      { pixels.length !== 0 && (
        <div className="row">
          <div className="col-12">
            <div className="text-right">
              <Button className="ml-3 btn btn-secondary btn-secondary-v2" onClick={() => setAddPendingPixelRow(true)}>
                <i className="fa fa-plus mr-2" />
                Add New Platform Pixel
              </Button>
            </div>
          </div>
        </div>
      )}
      <AddPixelModal
        show={showAddPixelModal}
        onHide={() => setShowAddPixelModal(false)}
        onSave={(pixelCode) => setPendingPixel(pixelCode)}
        container={activeContainer}
      />
      <AddTriggersModal
        show={showAddTriggersModal}
        onHide={() => setShowAddTriggersModal(false)}
        onSave={(trigger) => setPendingTrigger(trigger)}
        container={activeContainer}
        pendingPixel={pendingPixel}
      />
      <Button className="fixed-button btn btn-primary btn-primary-v2 ht-50" onClick={() => onBackClick()}>
        Close
      </Button>
    </div>
  );
};

export default ContainersIndex;
