import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { set } from 'lodash';

const AddPixelModal = ({
  show, onHide, onSave, container,
}) => {
  const [pixelCode, setPixelCode] = useState({
    code: '',
    container_id: container.id,
    name: '',
  });

  const onChangeCode = React.useCallback((value) => {
    setPixelCode((prevState) => ({ ...prevState, code: value }));
  }, [pixelCode]);

  const handleSave = () => {
    onSave(pixelCode);
    setPixelCode({
      code: '',
      container_id: '',
      name: '',
    });
    onHide();
  };

  useEffect(() => {
    if (show) {
      setPixelCode((prevState) => ({ ...prevState, container_id: container.id }));
    }
  }, [show, container.id]);

  return (
    <Modal show={show} onHide={onHide} dialogClassName="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>Add Platform Pixel</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="text-md-bold">Pixel Name</div>
          <div className="text-xs-regular gray-600">Give this platform pixel a unique name for your reference</div>
          <input
            type="text"
            className="form-control"
            value={pixelCode.name}
            onChange={(e) => setPixelCode((prevState) => ({ ...prevState, name: e.target.value }))}
            style={{ width: '100%', padding: '8px', margin: '10px 0' }}
          />
        </div>
        <div className="mt-2">
          <div className="text-md-bold">Add DSP Pixel</div>
          <span className="text-xs-regular gray-600">Copy & paste the DSP pixel below</span>
          <CodeMirror
            value=""
            height="200px"
            style={{ marginTop: '10px' }}
            extensions={[javascript({ jsx: true })]}
            onChange={onChangeCode}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="btn btn-secondary btn-secondary-v2 ht-50" onClick={onHide}>
          Close
        </Button>
        <Button className="ml-3 btn btn-primary btn-primary-v2 ht-50" onClick={handleSave}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddPixelModal;
