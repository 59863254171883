import React, { useState, useEffect, useRef } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import AutoNumeric from 'autonumeric';

const AdSpendCalculator = () => {
  const [MUVs, setMUVs] = useState(0);
  const [MUVsFormatted, setMUVsFormatted] = useState('0');
  const [AOV, setAOV] = useState(0);
  const [AOVFormatted, setAOVFormatted] = useState('0');
  const [budgetCapacity, setBudgetCapacity] = useState(0);
  const [budgetCapacityFormatted, setBudgetCapacityFormatted] = useState('0.00');
  const [revenueTotalFormatted, setRevenueTotalFormatted] = useState('0.00');

  const [conversionRate, setConversionRate] = useState(0);
  const [conversionRateFormatted, setConversionRateFormatted] = useState('0');
  const [ROAS] = useState(3);
  const [ROASTotal, setROASTotal] = useState(0);
  const MUVsInputRef = useRef(null);
  const AOVInputRef = useRef(null);
  const conversionRateInputRef = useRef(null);
  const budgetCapacityInputRef = useRef(null);
  const revenueTotalInputRef = useRef(null);

  const calculateROASandBudgetCapacity = () => {
    const conversionRateDecimal = conversionRate / 100;
    const currentConversions = MUVs * conversionRateDecimal;
    const incrementalConversions = currentConversions * 0.1;
    const CPA = AOV * 0.33;
    const maxBudget = CPA * incrementalConversions;

    setBudgetCapacity(isNaN(maxBudget) ? 0 : maxBudget);
    const calculatedROASTotal = ROAS * maxBudget;
    setROASTotal(isNaN(calculatedROASTotal) ? 0 : calculatedROASTotal);
    setBudgetCapacityFormatted(isNaN(maxBudget) ? '0.00' : maxBudget.toLocaleString('en-US', { minimumFractionDigits: 2 }));
    setRevenueTotalFormatted(isNaN(calculatedROASTotal) ? '0.00' : calculatedROASTotal.toLocaleString('en-US', { minimumFractionDigits: 2 }));
  };

  useEffect(() => {
    if (budgetCapacityInputRef.current) {
      new AutoNumeric(budgetCapacityInputRef.current).northAmerican();
    }

    if (revenueTotalInputRef.current) {
      new AutoNumeric(revenueTotalInputRef.current).northAmerican();
    }

    if (MUVsInputRef.current) {
      new AutoNumeric(MUVsInputRef.current, {
        decimalPlaces: 0,
        minimumValue: '0',
      });
    }

    if (AOVInputRef.current) {
      new AutoNumeric(AOVInputRef.current, {
        decimalPlaces: 2,
      });
    }

    if (conversionRateInputRef.current) {
      new AutoNumeric(conversionRateInputRef.current, {
        maximumValue: '100',
        decimalPlaces: 2,
      });
    }

    return () => {
      if (budgetCapacityInputRef.current) {
        AutoNumeric.remove(budgetCapacityInputRef.current);
      }
      if (revenueTotalInputRef.current) {
        AutoNumeric.remove(revenueTotalInputRef.current);
      }
      if (MUVsInputRef.current) {
        AutoNumeric.remove(MUVsInputRef.current);
      }
      if (AOVInputRef.current) {
        AutoNumeric.remove(AOVInputRef.current);
      }
      if (conversionRateInputRef.current) {
        AutoNumeric.remove(conversionRateInputRef.current);
      }
    };
  }, []);

  const handleMUVsChange = (e) => {
    const value = AutoNumeric.getAutoNumericElement(e.target).getNumericString();
    setMUVs(parseFloat(value));
    setMUVsFormatted(e.target.value);
  };

  const handleAOVChange = (e) => {
    const value = AutoNumeric.getAutoNumericElement(e.target).getNumericString();
    setAOV(parseFloat(value));
    setAOVFormatted(e.target.value);
  };

  const handleConversionRateChange = (e) => {
    const value = AutoNumeric.getAutoNumericElement(e.target).getNumericString();
    setConversionRate(parseFloat(value));
    setConversionRateFormatted(e.target.value);
  };

  useEffect(() => {
    calculateROASandBudgetCapacity();
  }, [MUVs, AOV, conversionRate]);

  return (
    <div className="form-v2">
      <p>Budget performance estimation based on your traffic, conversion rate, and order value.</p>
      <Form>
        <div style={{ marginBottom: 20, marginTop: 20 }}>
          <Form.Group className="col-12">
            <div className="label-v2 undefined" style={{ paddingLeft: '14%', zIndex: 9999 }}>
              <Form.Label>Monthly Unique Visitors</Form.Label>
            </div>
            <InputGroup className="mb-3" style={{ width: '100%' }}>
              <div className="input-v2-prepend" style={{ width: '14%' }}><span>MUVs</span></div>
              <Form.Control
                ref={MUVsInputRef}
                className="input-v2 right"
                type="text"
                value={MUVsFormatted}
                onChange={handleMUVsChange}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="col-12">
            <div className="label-v2 undefined" style={{ paddingLeft: '14%', zIndex: 9999 }}>
              <Form.Label>Conversion Rate</Form.Label>
            </div>
            <InputGroup className="mb-3" style={{ width: '100%' }}>
              <div className="input-v2-prepend" style={{ width: '14%' }}><span>%</span></div>
              <Form.Control
                ref={conversionRateInputRef}
                className="input-v2 right"
                type="text"
                value={conversionRateFormatted}
                onChange={handleConversionRateChange}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="col-12">
            <div className="label-v2 undefined" style={{ paddingLeft: '14%', zIndex: 9999 }}>
              <Form.Label>Average Order Value</Form.Label>
            </div>
            <InputGroup className="mb-3" style={{ width: '100%' }}>
              <div className="input-v2-prepend" style={{ width: '14%' }}><span>$</span></div>
              <Form.Control
                ref={AOVInputRef}
                className="input-v2 right"
                type="text"
                value={AOVFormatted}
                onChange={handleAOVChange}
              />
            </InputGroup>
          </Form.Group>
        </div>

        <h2>Results</h2>
        <Form.Group className="col-12">
          <div className="label-v2 undefined" style={{ paddingLeft: '14%', zIndex: 9999 }}>
            <Form.Label>Budget Capacity at 3:1 ROAS</Form.Label>
          </div>
          <InputGroup className="mb-3" style={{ width: '100%' }}>
            <div className="input-v2-prepend" style={{ width: '14%' }}><span>$</span></div>
            <Form.Control
              className="input-v2 right"
              type="text"
              value={budgetCapacityFormatted}
              readOnly
            />
          </InputGroup>
        </Form.Group>
        <Form.Group className="col-12">
          <div className="label-v2 undefined" style={{ paddingLeft: '14%', zIndex: 9999 }}>
            <Form.Label>Revenue Total</Form.Label>
          </div>
          <InputGroup className="mb-3" style={{ width: '100%' }}>
            <div className="input-v2-prepend" style={{ width: '14%' }}><span>$</span></div>
            <Form.Control
              className="input-v2 right"
              type="text"
              value={revenueTotalFormatted}
              readOnly
            />
          </InputGroup>
        </Form.Group>

      </Form>
    </div>
  );
};

export default AdSpendCalculator;
