import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import {
  formatQuery, QueryBuilder, Rule,
} from 'react-querybuilder';
import Select from 'react-select';
import checkMarkSVG from '../../../images/tag_manager/check_icon.svg';

const selectStyles = {
  control: (provided) => ({
    ...provided,
    alignItems: 'center',
    alignSelf: 'stretch',
    background: '#FFF',
    border: '1px solid #6778DA',
    borderRadius: '8px',
    boxShadow: '0px 0px 0px 4px rgba(70, 138, 240, 0.10), 0px 1px 2px 0px rgba(70, 138, 240, 0.05)',
    display: 'flex',
    fontWeight: '600',
    padding: '3px 0px',
  }),
  option: (provided) => ({
    ...provided,
    color: '#101828',
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '24px',
  }),
};

const RuleComponent = (props) => {
  // Use the last number in the path array as the rule's index
  const ruleIndex = props.path[props.path.length - 1] + 1;

  return (
    <div className="ruleGroup-item">
      <div className="vertical-label" style={{ background: '#F5E8FC', transform: 'rotate(180deg)', writingMode: 'vertical-rl' }}>
        {`Condition ${ruleIndex}`}
      </div>
      <Rule {...props} />
    </div>
  );
};

const Option = (props) => (
  <div className="rs-option" {...props.innerProps}>
    <div className={`${'rs-checkbox'} ${props.isSelected ? 'rs-checked' : 'rs-unchecked'}`}>
      {props.isSelected && <img src={checkMarkSVG} alt="checkmark" />}
    </div>
    {props.label}
  </div>
);

const formatGroupLabel = (data) => (
  <div className="rs-groupLabel text-sm-semibold">
    <span>{data.label}</span>
  </div>
);

const CustomFieldSelector = (fieldsForField) => (props) => {
  const groupedOptionsForField = [
    {
      label: 'Pages',
      options: fieldsForField,
    },
  ];

  return (
    <div className="d-flex flex-column">
      <div className="text-sm-semibold rule-field-label">Trigger Type</div>
      <Select
        styles={selectStyles}
        options={groupedOptionsForField}
        formatGroupLabel={formatGroupLabel}
        components={{ Option }}
        getOptionValue={(f) => f?.name}
        value={groupedOptionsForField[0].options.find((f) => f.name === props.value)}
        onChange={(v) => props.handleOnChange(v?.name)}
      />
    </div>
  );
};

const CustomOperatorSelector = (fieldsForOperator) => (props) => {
  const groupedOptionsForOperator = [
    {
      label: 'Conditions',
      options: fieldsForOperator[0].operators,
    },
  ];
  return (
    <div className="d-flex flex-column">
      <div className="text-sm-semibold rule-field-label">Conditions</div>
      <Select
        styles={selectStyles}
        options={groupedOptionsForOperator}
        formatGroupLabel={formatGroupLabel}
        components={{ Option }}
        getOptionValue={(f) => f?.name}
        value={groupedOptionsForOperator[0].options.find((f) => f.name === props.value)}
        onChange={(v) => props.handleOnChange(v?.name)}
      />
    </div>
  );
};

const ValueEditor = ({
  handleOnChange,
  value,
}) => (
  <div className="d-flex flex-column">
    <div className="text-sm-semibold rule-field-label">Enter URL or Keywords</div>
    <input
      className="rule-text-field"
      type="text"
      onChange={(e) => handleOnChange(e.target.value)}
      value={value}
    />
  </div>
);

const CustomRemoveRuleIcon = ({ handleOnClick, ...props }) => (
  <div className="pointer" onClick={handleOnClick} {...props}>
    <svg className="pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path d="M13.3333 5.5V4.83333C13.3333 3.89991 13.3333 3.4332 13.1517 3.07668C12.9919 2.76308 12.7369 2.50811 12.4233 2.34832C12.0668 2.16666 11.6001 2.16666 10.6667 2.16666H9.33333C8.39991 2.16666 7.9332 2.16666 7.57668 2.34832C7.26308 2.50811 7.00811 2.76308 6.84832 3.07668C6.66667 3.4332 6.66667 3.89991 6.66667 4.83333V5.5M8.33333 10.0833V14.25M11.6667 10.0833V14.25M2.5 5.5H17.5M15.8333 5.5V14.8333C15.8333 16.2335 15.8333 16.9335 15.5608 17.4683C15.3212 17.9387 14.9387 18.3212 14.4683 18.5608C13.9335 18.8333 13.2335 18.8333 11.8333 18.8333H8.16667C6.76654 18.8333 6.06647 18.8333 5.53169 18.5608C5.06129 18.3212 4.67883 17.9387 4.43915 17.4683C4.16667 16.9335 4.16667 16.2335 4.16667 14.8333V5.5" stroke="#6E20A2" strokeOpacity="1.0" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </div>
);

const AddTriggersModal = ({
  show, onHide, onSave, container, pendingPixel,
}) => {
  const fields = [
    {
      label: 'Page URL',
      name: 'pageUrl',
      operators: [
        { label: 'Exactly', name: '=' },
        { label: 'Custom', name: 'regex' },
        { label: 'Contains', name: 'contains' },
        { label: 'Begins With', name: 'beginsWith' },
        { label: 'Ends With', name: 'endsWith' },
      ],
    },

  ];

  const [query, setQuery] = useState({
    combinator: 'and',
    rules: [
      { field: 'pageUrl', operator: '=', value: 'https://' },
    ],
  });

  const [triggerCode, setTriggerCode] = useState({
    container_id: container.id,
    json: '',
  });

  const handleAddCondition = () => {
    document.querySelector('.ruleGroup-addRule').click();
  };

  const handleSave = () => {
    onSave(triggerCode);
    onHide();
  };

  const handleQueryChange = (newQuery) => {
    setQuery(newQuery);
    setTriggerCode((prevState) => ({
      ...prevState,
      json: formatQuery(newQuery, 'json'),
    }));
  };

  useEffect(() => {
    if (show) {
      setTriggerCode((prevState) => ({ ...prevState, container_id: container.id }));
    }
  }, [show, container.id]);

  return (
    <Modal show={show} onHide={onHide} centered dialogClassName="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>Trigger Configuration</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-md-bold black mb-2">{pendingPixel.name}</div>
        <QueryBuilder
          fields={fields}
          controlElements={{
            fieldSelector: CustomFieldSelector(fields),
            operatorSelector: CustomOperatorSelector(fields),
            removeRuleAction: CustomRemoveRuleIcon,
            rule: RuleComponent,
            valueEditor: ValueEditor,
          }}
          onQueryChange={(q) => handleQueryChange(q)}
          query={query}
          showCombinatorsBetweenRules
        />
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-secondary btn-secondary-v2 ht-50" onClick={() => handleAddCondition()}>
          <div className="d-flex align-items-center">
            <div className="mr-2 plus-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <path d="M10.0003 4.6665V16.3332M4.16699 10.4998H15.8337" stroke="#6E20A2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
            Add Conditions
          </div>
        </Button>
        <Button className="ml-3 btn btn-primary btn-primary-v2 ht-50" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddTriggersModal;
