import React, { Component } from 'react';
import moment from 'moment';
import { Button, Form } from 'react-bootstrap';

export default class CampaignDetailsComponent extends Component {
  constructor(props) {
    super(props);

    const { campaign, current_user } = this.props;

    this.state = {
      optimization_request_item: '',
      optimization_request_message_item: '',
      optimization_request_submitted_item: false,
    };
  }

  render() {
    const handleChange = (event) => {
      this.setState({ [event.target.name]: event.target.value });
    };

    const resetFormState = () => {
      this.setState({
        optimization_request_item: '',
        optimization_request_item_message: '',
        optimization_request_item_submitted: false,
      });
    };

    const handleSubmitOptimizationReq = (event) => {
      event.preventDefault();
      const { token } = this.props;
      const body = JSON.stringify({
        authenticity_token: token,
        campaign: this.props.campaign,
        optimization_request: this.props.optimization_request,
        optimization_request_item: this.state.optimization_request_item,
      });

      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.stopPropagation();
      } else {
        const method = 'POST';
        const path = `/optimization_requests/${this?.props?.optimization_request?.id}/optimization_request_items`;

        const requestOptions = {
          body,
          headers: { 'Content-Type': 'application/json' },
          method,
        };

        fetch(path, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              const { campaign } = this.props;
              const { optimization_requests } = campaign;
              this.props.optimization_request_items.unshift(data.optimization_request_item);
              this.setState({ optimization_request_item_submitted: true });
              this.setState({ optimization_request_item_message: data.message });
            } else {
              this.setState({ optimization_request_item_submitted: true });
              this.setState({ optimization_request_item_message: data.message });
            }
          });
      }
      this.setState({ validated: true });
    };

    return (
      <div style={{ padding: '0 40px' }}>
        <div className="row">
          <div className="col-12 grid-item">
            <br />
            <Form.Group controlId="optimization_request_id">
              <Form.Label className="label-v2 default-position form-subtitle">
                Optimization Request
              </Form.Label>
            </Form.Group>
            <div className="row" key={this?.props?.optimization_request?.id}>
              <div className="col-12 grid-item">
                <div className="card">
                  <div
                    className="card-header pl-3 pr-3"
                    style={{
                      alignItems: 'center',
                      backgroundColor: this?.props?.optimization_request?.actor === this?.props?.current_user?.id ? 'rgba(0, 0, 0, 0.03)' : 'rgb(94 18 128 / 21%)',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      className="cardtitle pt-1"
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <h6>
                        {this?.props?.optimization_request?.actor_name}
                      </h6>
                      <h6>{moment(this?.props?.optimization_request?.created_at).format('LLL')}</h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="p-3">
                        {this.props.optimization_request?.optimization_request}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-details">
              { !this.state.optimization_request_item_submitted && (
              <Form
                id={this.formId}
              >
                <Form.Group controlId="optimization_request">
                  <Form.Control
                    className="input-v2 textarea"
                    rows="5"
                    name="optimization_request_item"
                    type="text"
                    as="textarea"
                    onChange={handleChange}
                    value={this.state.optimization_request_item}
                  />
                </Form.Group>
                <Form.Group controlId="submit">
                  <Button className="btn btn-primary-v2" onClick={(e) => handleSubmitOptimizationReq(e)} style={{ width: '20%', float: 'right' }}>
                    Add Comment
                  </Button>
                </Form.Group>
              </Form>
              )}
              { this.state.optimization_request_item_submitted && (
                <div>
                  <div>{this.state.optimization_request_item_message}</div>
                  <br />
                  <Button className="btn btn-primary-v2" onClick={resetFormState} style={{ width: '20%' }}>
                    Submit Another
                  </Button>
                </div>
              )}
            </div>
            <br />
            <Form.Group controlId="optimization_request_id">
              <Form.Label className="label-v2 default-position form-subtitle">
                Comments
              </Form.Label>
            </Form.Group>
            { this.props.optimization_request_items.length === 0 && (
              <div className="row">
                <div className="col-12 grid-item">
                  <div className="card">
                    <div
                      className="card-header"
                      style={{
                        alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'center',
                      }}
                    >
                      <p className="mt-2">
                        There have been no comments on this optimization request yet
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {
              this.props.optimization_request_items.map((request) => (
                <div className="row" key={request?.id}>
                  <div className="col-12 grid-item">
                    <div className="card">
                      <div
                        className="card-header pl-3 pr-3"
                        style={{
                          alignItems: 'center',
                          backgroundColor: request?.actor === this.props?.current_user?.id ? 'rgba(0, 0, 0, 0.03)' : 'rgb(94 18 128 / 21%)',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div
                          className="cardtitle pt-1"
                          style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                          }}
                        >
                          <h6>
                            {request?.actor_name}
                          </h6>
                          <h6>{moment(request.created_at).format('LLL')}</h6>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="p-3">
                            {request?.content}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    );
  }
}
