import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';

const CreatingContainerModal = ({ onClose }) => (
  <>
    <Modal.Header onClick={onClose}>
      <Modal.Title>Creating Container</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="mt-4 generating-container">
        <Spinner style={{ height: '56px', width: '56px' }} animation="border" role="status" size="lg" variant="primary" />
        <div className="mt-4 mb-2 title">Generating a unique container code</div>
      </div>
    </Modal.Body>
  </>
);

export default CreatingContainerModal;
