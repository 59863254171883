import React, { useState } from 'react';
import { Table, Dropdown } from 'react-bootstrap';
import moment from 'moment';
import { toast } from 'react-toastify';
import CodeIcon from '../../../images/tag_manager/code_icon.svg';
import TriggerIcon from '../../../images/tag_manager/trigger_icon.svg';
import RightArrowIcon from '../../../images/tag_manager/arrow_right_icon.png';
import MinusCircleIcon from '../../../images/tag_manager/minus_circle_icon.svg';
import MinusCircleIconActive from '../../../images/tag_manager/minus_circle_icon_active.svg';
import EditPixelModal from './EditPixelModal';
import EditTriggersModal from './EditTriggersModal';
import { updatePixelAction, updateTriggerAction, deletePixelAction } from '../actions/pixel';

const operatorMapping = (operator) => {
  switch (operator) {
    case '=':
      return 'Exact';
    case 'endsWith':
      return 'Ends With';
    case 'startsWith':
      return 'Starts With';
    case 'contains':
      return 'Contains';
    case 'notContains':
      return 'Not Contain';
    default:
      return operator;
  }
};

const removeRowAndResetPendingPixel = (setAddPendingPixelRow, handleRemovePendingPixel) => {
  setAddPendingPixelRow(false);
  handleRemovePendingPixel();
};

const PixelTable = ({
  advertiser,
  token,
  pixels,
  setPixels,
  fieldTriggerMapping,
  onAddPixelClick,
  onAddTriggersClick,
  pendingPixel,
  activeContainer,
  addPendingPixelRow,
  setAddPendingPixelRow,
  handleRemovePendingPixel,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showEditPixelModal, setShowEditPixelModal] = useState(false);
  const [currentlyEditingPixel, setCurrentlyEditingPixel] = useState(null);
  const [showEditTriggerModal, setShowEditTriggerModal] = useState(false);
  const [currentlyEditingTrigger, setCurrentlyEditingTrigger] = useState(null);
  // Define the event handlers for the dropdown items
  const handleEditPixel = (pixel) => {
    // Handle the DSP Pixel editing logic here
    setShowDropdown(false); // Close the dropdown
    setShowEditPixelModal(true);
    setCurrentlyEditingPixel(pixel);
  };

  const handleEditTriggers = (pixel) => {
    // Handle the Triggers editing logic here
    setShowDropdown(false); // Close the dropdown
    setShowEditTriggerModal(true);
    setCurrentlyEditingTrigger(pixel);
  };

  const updatePixel = async (pixelCode) => {
    toast.info('Updating DSP Pixel..');
    try {
      const response = await updatePixelAction(pixelCode,
        activeContainer,
        token,
        advertiser);
      if (response.status) {
        toast.dismiss();
        toast.success('Pixel has been changed successfully');
        setPixels(response.all_results);
        setShowEditPixelModal(false);
        setCurrentlyEditingPixel(null);
        handleRemovePendingPixel();
      } else {
        // Handle failure scenario
        toast.dismiss();
        toast.error(response.message || 'An error occurred while editing a pixel.');
      }
    } catch (error) {
      // Handle unexpected errors, like network issues
      toast.dismiss();
      toast.error('An unexpected error occurred. Please try again.');
    }
  };

  const updateTrigger = async (pixelCode) => {
    toast.info('Updating Triggers..');
    try {
      const response = await updateTriggerAction(pixelCode,
        activeContainer,
        token,
        advertiser);
      if (response.status) {
        toast.dismiss();
        toast.success('Trigger configuration changed successfully');
        setPixels(response.all_results);
        setShowEditPixelModal(false);
        setCurrentlyEditingPixel(null);
        handleRemovePendingPixel();
      } else {
        // Handle failure scenario
        toast.dismiss();
        toast.error(response.message || 'An error occurred while editing a pixel.');
      }
    } catch (error) {
      // Handle unexpected errors, like network issues
      toast.dismiss();
      toast.error('An unexpected error occurred. Please try again.');
    }
  };

  const handleDeleteItem = async (pixel) => {
    toast.info('Deleting..');
    // Handle the deletion logic here
    try {
      const response = await deletePixelAction(pixel,
        activeContainer,
        token,
        advertiser);
      if (response.status) {
        toast.dismiss();
        toast.success('Trigger deleted successfully');
        setPixels(response.all_results);
        handleRemovePendingPixel();
      } else {
        // Handle failure scenario
        toast.dismiss();
        toast.error(response.message || 'An error occurred while editing a pixel.');
      }
    } catch (error) {
      // Handle unexpected errors, like network issues
      toast.dismiss();
      toast.error('An unexpected error occurred. Please try again.');
    }
  };

  return (

    <div className="pixelTableContainer">
      <Table responsive className="pixelTable" hover>
        <thead>
          <tr className="pixelTableHead">
            <th width="16.60%">Platform Pixels</th>
            <th width="9.25%">Triggers</th>
            <th width="11.25%">Constraints</th>
            <th width="26.25%">Keyword/URL</th>
            <th width="3.25%">Logic</th>
            <th width="5.40%">Status</th>
            <th width="6.30%"># of Hits</th>
            <th width="10.12%">Last Edited</th>
            <th width="2.30%" />
          </tr>
        </thead>
        <tbody className="pixelTableBody">
          {pixels?.map((pixel) => (
            <tr key={pixel.id}>
              <td>
                <div onClick={() => handleEditPixel(pixel)} className="pixel-name pointer">{pixel.name}</div>
              </td>
              <td className="table-outer-left">
                <div className="table-inner-left">
                  {pixel.triggers.rules.map((rule) => (
                    <div className="text-xs-medium purple table-inner-bubble" key={rule.id}>{fieldTriggerMapping(rule.field)}</div>
                  ))}
                </div>
              </td>
              <td className="table-outer-mid">
                <div className="table-inner-mid">
                  {pixel.triggers.rules.map((rule) => (
                    <div className="text-xs-medium grey-600 table-inner-bubble" key={rule.id}>
                      {operatorMapping(rule.operator)}
                    </div>
                  ))}
                </div>
              </td>
              <td className="table-outer-mid">
                <div className="table-inner-mid">
                  {pixel.triggers.rules.map((rule) => (
                    <div className="text-xs-medium orion-blue table-inner-bubble-last" key={rule.id}>{rule.value}</div>
                  ))}
                </div>
              </td>
              <td className="table-outer-right">
                <div className="table-inner-right">
                  {pixel.triggers.rules.map(() => (
                    <div className="text-xs-medium purple table-inner-bubble-end">
                      {pixel.triggers.combinator === 'and' ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                          <path d="M6 2.5V9.5M2.5 6H9.5" stroke="#6E20A1" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                          <path d="M8.5 1L10.5 3M10.5 3L8.5 5M10.5 3H3.9C3.05992 3 2.63988 3 2.31901 3.16349C2.03677 3.3073 1.8073 3.53677 1.66349 3.81901C1.5 4.13988 1.5 4.55992 1.5 5.4V5.5M1.5 9H8.1C8.94008 9 9.36012 9 9.68099 8.83651C9.96323 8.6927 10.1927 8.46323 10.3365 8.18099C10.5 7.86012 10.5 7.44008 10.5 6.6V6.5M1.5 9L3.5 11M1.5 9L3.5 7" stroke="#6E20A1" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      )}
                      {pixel?.triggers?.combinator?.toUpperCase()}
                    </div>
                  ))}
                </div>
              </td>
              <td>
                <div>
                  {pixel.status ? (
                    <div className="green-dot" />
                  ) : (
                    <div className="red-dot" />
                  )}
                </div>
              </td>
              <td>{pixel.hits}</td>
              <td>{moment(pixel.updated_at).fromNow()}</td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle as="div" id="dropdown-custom-components">
                    <div style={{ cursor: 'pointer' }}>
                      <i className="fa fa-ellipsis-v" />
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleEditPixel(pixel)}>
                      Edit DSP Pixel
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleEditTriggers(pixel)}>
                      Edit Triggers
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDeleteItem(pixel)}>
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {(pixels.length === 0 || addPendingPixelRow) && (
        <Table responsive className="pixelTable pixelTableBottom" hover>
          <tbody>
            <tr>
              <td colSpan="4">
                {pendingPixel.name ? (
                  <div className="block-container">
                    <div className="icon-image">
                      <div className="icon-container"><img src={CodeIcon} alt="Code Snippet" /></div>
                      <div>
                        <div className="text-sm-semibold purple block-title">{pendingPixel.name}</div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="block-container" onClick={() => onAddPixelClick(activeContainer)}>
                    <div className="icon-image">
                      <div className="icon-container"><img src={CodeIcon} alt="Code Snippet" /></div>
                      <div>
                        <div className="text-sm-semibold purple block-title">Place DSP Pixel</div>
                        <div className="text-xs-regular grey-600 block-subtitle">Add the DSP pixel you would like to manage</div>
                      </div>
                    </div>
                    <div><img src={RightArrowIcon} alt="Right Arrow" /></div>
                  </div>
                )}
              </td>
              <td colSpan="4">
                <div className="block-container" onClick={() => onAddTriggersClick(activeContainer)}>
                  <div className="icon-image">
                    <div className="icon-container"><img src={TriggerIcon} alt="Trigger Configuration" /></div>
                    <div>
                      <div className="text-sm-semibold purple block-title">Add Triggers</div>
                      <div className="text-xs-regular grey-600 block-subtitle">Add triggers to control when this pixel fires</div>
                    </div>
                  </div>
                  <div><img src={RightArrowIcon} alt="Right Arrow" /></div>
                </div>
              </td>
              <td colSpan="1">
                {pendingPixel.name
                  ? (
                    <img
                      className="mr-3 pointer"
                      onClick={() => removeRowAndResetPendingPixel(
                        setAddPendingPixelRow,
                        handleRemovePendingPixel,
                      )}
                      src={MinusCircleIconActive}
                      alt="Minus Circle Active"
                    />
                  )
                  : (

                    <img
                      className="mr-3"
                      src={MinusCircleIcon}
                      alt="Minus Circle"
                    />
                  )}
              </td>
            </tr>
          </tbody>
        </Table>
      )}
      {currentlyEditingPixel && (
        <EditPixelModal
          show={showEditPixelModal}
          onHide={() => setShowEditPixelModal(false)}
          onEdit={(pixelCode) => updatePixel(pixelCode)}
          pixel={currentlyEditingPixel}
          container={activeContainer}
        />
      )}
      {currentlyEditingTrigger && (
        <EditTriggersModal
          show={showEditTriggerModal}
          onHide={() => setShowEditTriggerModal(false)}
          onEdit={(pixelCode) => updateTrigger(pixelCode)}
          pixel={currentlyEditingTrigger}
          container={activeContainer}
        />
      )}
    </div>
  );
};

export default PixelTable;
