import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Modal,
} from 'react-bootstrap';
import { createContainerAction, getContainersAction } from '../actions/container';
import AddContainerFormModal from './AddContainerFormModal';
import CreatingContainerModal from './CreatingContainerModal';
import InstallContainerModal from './InstallContainerModal';

const AddContainerComponent = ({
  onClose, token, advertiser, setContainers,
}) => {
  const [containerName, setContainerName] = useState('');
  const [isCreatingContainer, setIsCreatingContainer] = useState(false);
  const [isContainerCreated, setIsContainerCreated] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [container, setContainer] = useState('');
  const [containerCode, setContainerCode] = useState('');

  const handleCreateContainerClick = async () => {
    if (!containerName.trim()) {
      toast.error('Enter a container name to continue');
      return;
    }

    setIsCreatingContainer(true);
    setIsContainerCreated(false);

    try {
      const newContainer = await createContainerAction(containerName, toast, token, advertiser);

      setIsCreatingContainer(false);
      setIsContainerCreated(true);
      setContainer(newContainer);
      getContainersAction(advertiser).then((data) => {
        setContainers(data);
      });

      setContainerCode(`<script>
    (function(w,d,s){var i=new URLSearchParams(w.location.search).has('debug'),
    f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async=!0;
    j.src='https://orion-pixel.s3-accelerate.amazonaws.com/${process.env.NODE_ENV[0]}/${newContainer.advertiser_id}-${newContainer.id}.js';j.onload=function(){i&&alert('Loaded');};
    f.parentNode.insertBefore(j,f);})(window,document,'script');
</script>`);
    } catch (error) {
      setIsCreatingContainer(false);
      setIsContainerCreated(false);
      toast.error('Error:', error.message);
    }
  };

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(text);
    }
    return document.execCommand('copy', true, text);
  }

  // onClick handler function for the copy button
  const handleCreatedCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(containerCode)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        toast.error('Failed to copy!', err);
      });
  };

  const handleCreatedDoneClick = () => {
    setIsContainerCreated(false);
    onClose();
  };

  return (
    <div
      className="add-container-form modal show"
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'block',
        height: '100%',
        left: 0,
        overflowY: 'auto',
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 1050,
      }}
    >
      <Modal.Dialog size="lg" aria-labelledby="add-container-form-modal" centered>
        {
          (() => {
            if (!isCreatingContainer && !isContainerCreated) {
              return (
                <AddContainerFormModal
                  onClose={onClose}
                  containerName={containerName}
                  setContainerName={setContainerName}
                  handleCreateContainerClick={handleCreateContainerClick}
                />
              );
            }
            if (isCreatingContainer && !isContainerCreated) {
              return (
                <CreatingContainerModal
                  onClose={onClose}
                />
              );
            }
            if (isContainerCreated && !isCreatingContainer) {
              return (
                <InstallContainerModal
                  container={container}
                  onClose={onClose}
                  containerCode={containerCode}
                  handleCreatedCopyClick={handleCreatedCopyClick}
                  handleCreatedDoneClick={handleCreatedDoneClick}
                  isCopied={isCopied}
                  advertiser={advertiser}
                />
              );
            }
          })()
        }
      </Modal.Dialog>
    </div>
  );
};

export default AddContainerComponent;
