import React from 'react';
import { Button } from 'react-bootstrap';
import PinIcon from '../../../images/tag_manager/pin_icon.png';

const EmptyContainersMessage = ({ setIsModalOpen }) => (
  <div className="mt-5 container">
    <div className="pin-icon-container">
      <img src={PinIcon} alt="pin" />
    </div>
    <div className="mt-3 second-title">Keep & track all of your pixels in one place</div>
    <div className="mt-2 description">Add a Container to get started.</div>
    <Button
      onClick={() => setIsModalOpen(true)}
      className="mb-5 mt-4 ml-3 btn btn-secondary btn-secondary-v2 ht-50"
    >
      Add a Container
    </Button>
  </div>
);

export default EmptyContainersMessage;
