import React from 'react';
import { CodeBlock } from 'react-code-blocks';
import { Modal, Button } from 'react-bootstrap';
import CheckIcon from '../../../images/tag_manager/check_icon.png';

const InstallContainerModal = ({
  container,
  onClose,
  containerCode,
  handleCreatedCopyClick,
  handleCreatedDoneClick,
  isCopied,
  advertiser,
}) => (
  <>
    <Modal.Header closeButton onClick={onClose}>
      <Modal.Title>Install Container</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="install-container">
        <div className="row">
          <div className="col-md-2 check-icon-container">
            <img src={CheckIcon} alt="Checkmark" />
          </div>
          <div className="col-md-6 grey-600">
            <div className="text-xl-semibold purple">Container successfully created!</div>
            <div>
              <span className="bold-700">Advertiser:</span>
              {' '}
              {advertiser.name}
            </div>
            <div>
              <span className="bold-700">Container name:</span>
              {' '}
              {container.name}
            </div>
          </div>
          <div className="col-md-4 text-right text-xs-regular">
            <span className="bold-700 grey-600">Container ID:</span>
            {' '}
            <span className="purple">
              ORC-
              {container.id}
            </span>
          </div>
        </div>

        <hr />

        <div className="text-lg-semibold grey-600">Add container to your website</div>
        <p className="grey-600">
          Copy the Orion Smart Container code below and install it on every page of
          your website. Place this code as high in the
          {' '}
          <span className="bold-700">{'<head>'}</span>
          {' '}
          of the page as
          possible.
        </p>

        <div className="row code-block mb-3">
          <div className="col-md-11">
            <CodeBlock
              text={containerCode}
              language="javascript"
              showLineNumbers={false}
            />
          </div>
          <div className="col-md-1 copy-code-block text-right">
            <div
              className="copy-button"
              onClick={handleCreatedCopyClick}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
                <path d="M9.375 4.25H18.25C21.0503 4.25 22.4504 4.25 23.52 4.79497C24.4608 5.27434 25.2257 6.03924 25.705 6.98005C26.25 8.04961 26.25 9.44974 26.25 12.25V21.125M7.75 26.75H17.875C19.2751 26.75 19.9752 26.75 20.51 26.4775C20.9804 26.2378 21.3628 25.8554 21.6025 25.385C21.875 24.8502 21.875 24.1501 21.875 22.75V12.625C21.875 11.2249 21.875 10.5248 21.6025 9.99003C21.3628 9.51962 20.9804 9.13717 20.51 8.89749C19.9752 8.625 19.2751 8.625 17.875 8.625H7.75C6.34987 8.625 5.6498 8.625 5.11502 8.89749C4.64462 9.13717 4.26217 9.51962 4.02248 9.99003C3.75 10.5248 3.75 11.2249 3.75 12.625V22.75C3.75 24.1501 3.75 24.8502 4.02248 25.385C4.26217 25.8554 4.64462 26.2378 5.11502 26.4775C5.6498 26.75 6.34987 26.75 7.75 26.75Z" stroke="#6E20A2" stroke-width="2.83333" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </div>
        </div>
        <p className="grey-600">
          <span className="bold-700">Important:</span>
          {' '}
          The container must be installed in order to manage all your platform
          pixels from a single source. For more information about installing the
          Orion Smart Container snippet,
          email
          {' '}
          <a className="link" href="mailto:support@theversion2.com">support@theversion2.com</a>
          .
        </p>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button
        variant="btn btn-secondary btn-secondary-v2 ht-50"
        onClick={handleCreatedCopyClick}
      >
        <div className="d-flex align-items-center">
          <div className="mr-2 copy-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
              <path d="M6.25 3H12.1667C14.0335 3 14.9669 3 15.68 3.36331C16.3072 3.68289 16.8171 4.19282 17.1367 4.82003C17.5 5.53307 17.5 6.46649 17.5 8.33333V14.25M5.16667 18H11.9167C12.8501 18 13.3168 18 13.6733 17.8183C13.9869 17.6586 14.2419 17.4036 14.4017 17.09C14.5833 16.7335 14.5833 16.2668 14.5833 15.3333V8.58333C14.5833 7.64991 14.5833 7.1832 14.4017 6.82668C14.2419 6.51308 13.9869 6.25811 13.6733 6.09832C13.3168 5.91667 12.8501 5.91667 11.9167 5.91667H5.16667C4.23325 5.91667 3.76654 5.91667 3.41002 6.09832C3.09641 6.25811 2.84144 6.51308 2.68166 6.82668C2.5 7.1832 2.5 7.64991 2.5 8.58333V15.3333C2.5 16.2668 2.5 16.7335 2.68166 17.09C2.84144 17.4036 3.09641 17.6586 3.41002 17.8183C3.76654 18 4.23325 18 5.16667 18Z" stroke="#6E20A2" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          {isCopied ? 'Copied!' : 'Copy'}
        </div>
      </Button>
      <Button
        variant="btn btn-primary btn-primary-v2 ht-50"
        onClick={handleCreatedDoneClick}
      >
        Done
      </Button>
    </Modal.Footer>
  </>
);

export default InstallContainerModal;
