import React from 'react';
import { Button } from 'react-bootstrap';

const SlideOutHeader = ({
  setIsModalOpen, title, subtitle, containers,
}) => (
  <div className="row">
    <div className="col-6">
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
    </div>
    <div className="col-6 text-right">
      {containers && containers.length > 0 && (
      <Button
        onClick={() => setIsModalOpen(true)}
        className="mb-5 mt-4 ml-3 btn btn-secondary btn-secondary-v2 ht-50"
      >
        Add a Container
      </Button>
      )}
    </div>
  </div>
);

export default SlideOutHeader;
