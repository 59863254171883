import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { moneyFormatter } from '../../common/utils';
import LinkButton from '../../components/LinkButton';
import SlideOutComponent from '../TagManager/SlideOutComponent';

function onRowClick(event) {
  window.location.assign(event.data.link);
}

const CampaignIndexComponent = ({
  campaigns: propCampaigns, link, token, advertiser,
}) => {
  const columns = [
    {
      headerName: 'Status', field: 'status', sortable: true, flex: 1,
    },
    {
      headerName: 'Campaign', field: 'name', sortable: true, flex: 1,
    },
    {
      headerName: 'Flight', field: 'flight', sortable: true, flex: 1,
    },
    {
      headerName: 'Campaign Activation', field: 'humanized_campaign_type', sortable: true, flex: 1,
    },
    {
      headerName: 'Total Budget',
      field: 'budget',
      sortable: true,
      cellRenderer: (params) => moneyFormatter(params.value),
      comparator: (a, b) => a - b,
      cellStyle: { textAlign: 'center' },
      headerClass: 'center-header',
      flex: 1,
    },
    {
      headerName: 'Goal(s)', field: 'goals', sortable: true, flex: 1,
    },
  ];

  const setupTableData = () => propCampaigns.map(({
    id, status, name, budget, flight, campaign_type, humanized_campaign_type, goals,
  }) => ({
    budget,
    flight,
    campaign_type,
    humanized_campaign_type,
    goals,
    link: `/campaigns/${id}`,
    name,
    status,
  }));

  const [campaigns] = useState(setupTableData());
  const [tableData, setTableData] = useState(campaigns);
  const [isPaneOpen, setIsPaneOpen] = useState(false);
  const [isPaneOpenLeft] = useState(false);

  const onSearchInputChange = (searchTerm) => {
    const data = searchTerm !== ''
      ? campaigns.filter((campaign) => campaign.name.toLowerCase().includes(searchTerm.toLowerCase()))
      : [...campaigns];
    setTableData(data);
  };

  const onRequestClose = () => {
    setIsPaneOpen(false);
  };

  return (
    <div>
      <div className="row">
        <div className="col-4 grid-item">
          <input
            className="form-control"
            type="text"
            placeholder="Search campaigns"
            onChange={(event) => onSearchInputChange(event.target.value)}
          />
        </div>
        <div className="col-6 grid-item">
          <LinkButton
            text="Plan a campaign"
            link={`${link}new`}
            icon="fas fa-plus-circle icon"
            buttonClass="btn btn-primary btn-primary-v2 ht-50"
            tooltip="Enter any additional details to launch a new campaign"
          />
          <Button
            onClick={() => setIsPaneOpen(true)}
            className="ml-3 btn btn-secondary btn-secondary-v2 ht-50"
            tooltip="Enter any additional details to launch a new campaign"
          >
            Pixel Container
          </Button>
        </div>
      </div>
      <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
        <AgGridReact
          style={{ width: '100%' }}
          rowSelection="single"
          onRowClicked={onRowClick}
          columnDefs={columns}
          rowData={tableData}
          domLayout="autoHeight"
        />
      </div>
      <SlideOutComponent
        token={token}
        advertiser={advertiser}
        isPaneOpen={isPaneOpen}
        setPaneOpen={setIsPaneOpen}
        isPaneOpenLeft={isPaneOpenLeft}
        onRequestClose={onRequestClose}
      />
      <ToastContainer />
    </div>

  );
};

CampaignIndexComponent.propTypes = {
  campaigns: PropTypes.arrayOf(PropTypes.object).isRequired,
  link: PropTypes.string.isRequired,
};

export default CampaignIndexComponent;
