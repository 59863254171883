import React, { useEffect } from 'react';

const BunnyWidget = (config) => {
  const postProjectEventHandler = (ev) => {
    if (ev.detail.data.success_hash) {
      window.location.href = `${ev.detail.data.success_hash}`;
    } else {
      window.location.href = `${ev.detail.data.error_hash}`;
    }
  };
  useEffect(() => {
    // TODO: This needs to be refactored or
    // the solution to solve the floating quote box needs to be found (it's a bug in the widget)
    setTimeout(() => {
      document.querySelector('#widget').shadowRoot.querySelector('main > div > bs-form').shadowRoot.querySelector('#quoting_field_key').style.cssText = 'position: "static"';
    }, 3000);
    const widget = document.getElementById('widget');
    widget.addEventListener('bsSubmit', postProjectEventHandler);
    return () => {
      widget.removeEventListener('bsSubmit', postProjectEventHandler);
    };
  }, []);

  return (
    <section className="container" id="project">
      <be-widget
        id="widget"
        key-value={config.api}
        client-id={config.email}
      />
    </section>
  );
};
export default BunnyWidget;
