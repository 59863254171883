import React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

const AddContainerFormModal = ({
  onClose, containerName, setContainerName, handleCreateContainerClick,
}) => (
  <>
    <Modal.Header closeButton onClick={onClose}>
      <Modal.Title>Add a Container</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="form-title">Container Name</div>
      <div className="mb-2 form-subtitle">Give this container a unique name for your reference</div>
      <Form.Control
        type="text"
        id="containerName"
        aria-describedby="containerName"
        value={containerName}
        onChange={(e) => setContainerName(e.target.value)}
      />
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={() => onClose(true)}variant="btn btn-secondary btn-secondary-v2 ht-50">Cancel</Button>
      <Button
        variant="btn btn-primary btn-primary-v2 ht-50"
        disabled={!containerName.trim()}
        onClick={() => handleCreateContainerClick()}
      >
        Create
      </Button>
    </Modal.Footer>
  </>
);

export default AddContainerFormModal;
