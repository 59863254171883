import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BunnyWidget from './components/BunnyWidget';

export default class BunnyStudioIndexComponent extends Component {
  constructor(props) {
    super(props);

    this.props = {
      api: props.api,
      email: props.email,
    };
  }

  render() {
    return (
      <BunnyWidget email={this.props.email} api={this.props.api} />
    );
  }
}

BunnyStudioIndexComponent.propTypes = {
  api: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};
