import React, { Component } from 'react';
import { Tabs } from 'react-simple-tabs-component';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Form } from 'react-bootstrap';
import { statusColor, statusLabel } from '../../constants';
import { formatRange, moneyFormatter, numberFormatter } from '../../common/utils';
import AffinitiesList from '../../components/AffinitiesList';
import CampaignActionItemsFormFragment from './components/CampaignActionItemsFormFragment';
import FileUpload from '../../components/fileUpload';

export default class CampaignDetailsComponent extends Component {
  constructor(props) {
    super(props);
    this.tabs = [
      {
        Component: this.campaignAudienceTab,
        index: 1,
        label: 'Campaign Targeting',
      },
      {
        Component: this.campaignGoalsTab,
        index: 2,
        label: 'Goals',
      },
    ];

    this.actionItemsNeeded(this.tabs);

    const { campaign: { campaign_type: campaignType } } = this.props;

    if (campaignType === 'trade_side_platform_auto_setup') {
      this.tabs.push(
        {
          Component: this.uploadCreativeTab,
          index: 3,
          label: 'Creative Upload',
        },
      );
    }

    this.tabs.push(
      {
        Component: this.optimizationRequestTab,
        index: 4,
        label: 'Optimization Request',
      },
    );

    this.state = {
      selectedTab: 0,
      creatives: this.props.campaign.creatives,
    };
  }

  actionItemsNeeded(tabs) {
    const {
      campaign: {
        crm_data,
        brand_safety,
        contextual_targeting,
      },
    } = this.props;

    const itemsValue = [crm_data, brand_safety, contextual_targeting];
    const { campaign: { campaign_type } } = this.props;

    if (itemsValue.some((v) => v === true) || campaign_type === 'trade_side_platform_auto_setup') {
      tabs.unshift({
        Component: this.campaignActionItem, // Tab Component
        index: 0, // Tab index
        label: 'Action Items', // Tab title
      });
    }
  }

  selectCreativeUploadTab() {
    const btn = document.getElementById('btn-3');
    if (btn) {
      btn.click();
    }
  }

  setSelectedTab(index) {
    this.setState({
      selectedTab: index,
    });
  }

  campaignActionItem = () => {
    const {
      campaign: {
        id,
        crm_data,
        brand_safety,
        contextual_targeting,
        crm_data_checked,
        brand_safety_text,
        contextual_targeting_text,
        campaign_type,
        is_creative_uploaded,
      },
      token,
    } = this.props;

    return (
      <CampaignActionItemsFormFragment
        crmData={crm_data}
        brandSafety={brand_safety}
        contextualTargeting={contextual_targeting}
        crmDataChecked={crm_data_checked}
        brandSafetyText={brand_safety_text}
        contextualTargetingText={contextual_targeting_text}
        campaignId={id}
        token={token}
        campaignType={campaign_type}
        isCreativeUploaded={is_creative_uploaded}
        selectCreativeUploadTab={this.selectCreativeUploadTab}
      />
    );
  }

  campaignGoalsTab = () => {
    const {
      campaign: {
        objectives,
      },
    } = this.props;

    return (
      objectives.map((objective, i) => (
        this.objectiveSegment(objective, i)
      ))
    );
  }

  optimizationRequestTab = () => {
    const {
      campaign: {
        id,
        crm_data,
        brand_safety,
        contextual_targeting,
        crm_data_checked,
        brand_safety_text,
        contextual_targeting_text,
        campaign_type,
        is_creative_uploaded,
      },
    } = this.props;

    const handleChange = (event) => {
      this.setState({ [event.target.name]: event.target.value });
    };

    const resetFormState = () => {
      this.setState({
        optimization_request: '',
        optimization_request_message: '',
        optimization_request_submitted: false,
      });
    };

    const navigateToItem = (id) => {
      window.location.href = `/optimization_requests/${id}`;
    };

    const handleSubmitOptimizationReq = (event) => {
      event.preventDefault();
      const { token } = this.props;
      const submitState = { ...this.state };
      const body = JSON.stringify({
        authenticity_token: token,
        campaign: this.props.campaign,
        optimization_request: this.state.optimization_request,
        request_type: event.target.value,
      });

      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.stopPropagation();
      } else {
        const method = 'POST';
        const path = `/campaigns/${this?.props?.campaign?.id}/optimization_requests`;

        const requestOptions = {
          body,
          headers: { 'Content-Type': 'application/json' },
          method,
        };

        fetch(path, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              const { campaign } = this.props;
              const { optimization_requests } = campaign;
              optimization_requests.unshift(data.optimization_request);
              this.setState({ optimization_request_submitted: true });
              this.setState({ optimization_request_message: data.message });
            } else {
              this.setState({ optimization_request_submitted: true });
              this.setState({ optimization_request_message: data.message });
            }
          });
      }
      this.setState({ validated: true });
    };

    return (
      <div style={{ padding: '0 40px' }}>
        <div className="row">
          <div className="col-12 grid-item">
            <div className="card-details">
              { !this.state.optimization_request_submitted && (
              <Form
                id={this.formId}
              >
                <Form.Group controlId="optimization_request">
                  <Form.Label className="label-v2 default-position form-subtitle">New Optimization Request</Form.Label>
                  <Form.Control
                    className="input-v2 textarea"
                    rows="5"
                    name="optimization_request"
                    type="text"
                    as="textarea"
                    onChange={handleChange}
                    value={this.state.optimization_request}
                  />
                </Form.Group>
                <Form.Group controlId="submit">
                  <Button className="btn btn-primary-v2" onClick={(e) => handleSubmitOptimizationReq(e)} style={{ width: '50%' }}>
                    Submit Request
                  </Button>
                </Form.Group>
              </Form>
              )}
              { this.state.optimization_request_submitted && (
                <div>
                  <div>{this.state.optimization_request_message}</div>
                  <br />
                  <Button className="btn btn-primary-v2" onClick={resetFormState} style={{ width: '20%' }}>
                    Submit Another
                  </Button>
                </div>
              )}
            </div>
            <br />
            <Form.Group controlId="optimization_request">
              <Form.Label className="label-v2 default-position form-subtitle">Optimization Requests</Form.Label>
            </Form.Group>
            {
              this.props.campaign.optimization_requests.map((request) => (
                <div className="row" key={request?.id}>
                  <div className="col-12 grid-item">
                    <div className="card">
                      <div
                        className="card-header"
                        style={{
                          alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                        }}
                      >
                        <div className="cardtitle">
                          <h6>
                            Request #
                            {request.id}
                          </h6>
                          <p>{moment(request.created_at).format('LLL')}</p>
                        </div>
                        <div>
                          <Button className="btn btn-primary-v2 btn-sm" onClick={(e) => navigateToItem(request.id)}>
                            View
                          </Button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="p-3">
                            {request?.optimization_request?.substring(0, 300)}
                            { request?.optimization_request?.length > 300 ? '...' : '' }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    );
  };

  objectiveSegment = (objective, i) => {
    const { field_mapping: fieldMapping } = this.props;

    const fields = fieldMapping.field_options[objective.kpi] || [];

    return (
      <div key={i}>
        {i > 0
          && <hr className="w-100" />}
        <div style={{ padding: '0 40px' }}>
          <div className="row">
            <div className="col-12 grid-item">
              <div className="row">
                <div className="col-12 grid-item">
                  <div className="details-card">
                    <h6>Goal</h6>
                    <p className="goal-label">
                      {objective.goal || '-'}
                    </p>
                  </div>
                </div>
                <div className="col-4 grid-item">
                  <h6>KPI</h6>
                  <p>
                    {objective.kpi}
                  </p>
                </div>
                {fields.includes('budget')
                  && (
                  <div className="col-4 grid-item">
                    <h6>Budget</h6>
                    <p>
                      {moneyFormatter(objective.budget)}
                    </p>
                  </div>
                  )}
                {fields.includes('desired_dcpm')
                  && (
                  <div className="col-4 grid-item">
                    <h6>Desired dCpm</h6>
                    <p>
                      {moneyFormatter(objective.desired_dcpm)}
                    </p>
                  </div>
                  )}
                {fields.includes('desired_dcpm') && fields.includes('budget')
                  && (
                  <div className="col-4 grid-item">
                    <h6>Impressions</h6>
                    <p>
                      {numberFormatter(objective.impressions)}
                    </p>
                  </div>
                  )}
                {fields.includes('target_ctr')
                  && (
                  <div className="col-4 grid-item">
                    <h6>Target CTR</h6>
                    <p>
                      {`${objective.target_ctr}%`}
                    </p>
                  </div>
                  )}
                {fields.includes('video_completion_rate')
                  && (
                  <div className="col-4 grid-item">
                    <h6>Video Completion Rate</h6>
                    <p>
                      {`${objective.video_completion_rate}%`}
                    </p>
                  </div>
                  )}
                {fields.includes('target_cpa')
                  && (
                  <div className="col-4 grid-item">
                    <h6>CPA Goal</h6>
                    <p>
                      {moneyFormatter(objective.target_cpa)}
                    </p>
                  </div>
                  )}
                {fields.includes('average_order_value')
                  && (
                  <div className="col-4 grid-item">
                    <h6>Average Order Value</h6>
                    <p>
                      {moneyFormatter(objective.average_order_value)}
                    </p>
                  </div>
                  )}
                {fields.includes('target_roas')
                  && (
                  <div className="col-4 grid-item">
                    <h6>ROAS Goal</h6>
                    <p>
                      {`${objective.target_roas}%`}
                    </p>
                  </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  campaignAudienceTab = () => {
    const {
      campaign: {
        geography,
        affinities,
        geo_fence,
        targeting_notes,
      },
    } = this.props;

    return (
      <div style={{ padding: '0 40px' }}>
        <div className="row">
          <div className="col-6 grid-item">
            <div className="row">
              <div className="col-12 grid-item">
                <div className="row">
                  <div className="col-6 grid-item">
                    <h6>Gender</h6>
                    <p>{this._getGenderLabel() || '-'}</p>
                  </div>
                  <div className="col-6 grid-item">
                    <h6>Age</h6>
                    {this._getAgeLabels() || '-'}
                  </div>
                </div>
              </div>
              <div className="col-12 grid-item">
                <div className="row">
                  <div className="col-6 grid-item">
                    <h6>Demographic Notes</h6>
                    <p>{targeting_notes}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 grid-item">
            <div className="row">
              <div className="col-12 grid-item">
                <div className="row">
                  <div className="col-6 grid-item">
                    <h6>Geography</h6>
                    {
                      geography.length !== 0 ? geography.map((geographyItem, index) => (
                        <div key={index} className="pill blue">{geographyItem}</div> // eslint-disable-line
                      )) : '-'
                    }
                  </div>
                  <div className="col-6 grid-item">
                    <h6>Geo Fence</h6>
                    {
                      geo_fence.length !== 0 ? geo_fence.map((geoItem, index) => (
                        <div key={index} className="pill blue">{geoItem}</div> // eslint-disable-line
                      )) : '-'
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 grid-item">
            <div className="details-card">
              <h6>Affinities</h6>
              {affinities ? Object.keys(affinities).length > 0 ? <AffinitiesList affinities={affinities} /> : '-' : '-'}
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleOnFileChange = (file, status) => {
    if (status === 'done') {
      const data = JSON.parse(file.xhr.response);
      const updatedCreatives = [...this.state.creatives];
      updatedCreatives.push(data);
      this.setState({
        creatives: updatedCreatives,
      });
      file.remove();
    }
  }

  handleDeleteCreative = (creativeId, index) => {
    const requestOptions = {
      headers: { 'Content-Type': 'application/json' },
      method: 'DELETE',
    };

    const deleteCreativeUrl = `/creatives/${creativeId}`;

    fetch(deleteCreativeUrl, requestOptions)
      .then((response) => {
        if (response.ok) {
          const updatedCreatives = [...this.state.creatives];
          updatedCreatives.splice(index, 1);
          this.setState({
            creatives: updatedCreatives,
          });
        }
      });
  }

  uploadCreativeTab = () => {
    const {
      campaign: {
        id: campaignId,
      },
    } = this.props;

    return (
      <div>
        <FileUpload
          creatives={this.state.creatives}
          campaignId={campaignId}
          handleOnFileChange={this.handleOnFileChange}
          handleDeleteCreative={this.handleDeleteCreative}
        />
      </div>
    );
  }

  _getGenderLabel() {
    const {
      campaign: {
        age_range_male: ageRangeMale,
        age_range_female: ageRangeFemale,
      },
    } = this.props;

    if (!!ageRangeMale && !!ageRangeFemale) {
      return 'Male & Female';
    }
    if (ageRangeMale) {
      return 'Male';
    }
    if (ageRangeFemale) {
      return 'Female';
    }

    return '';
  }

  _getAgeLabels() {
    const {
      campaign: {
        age_range_male: ageRangeMale,
        age_range_female: ageRangeFemale,
      },
    } = this.props;

    if (!!ageRangeMale && !!ageRangeFemale) {
      return (
        <>
          <p>{`Male: ${formatRange(ageRangeMale)}`}</p>
          <br />
          <p>{`Female: ${formatRange(ageRangeFemale)}`}</p>
        </>
      );
    }
    if (ageRangeMale) {
      return (<p>{`Male: ${formatRange(ageRangeMale)}`}</p>);
    }
    if (ageRangeFemale) {
      return (<p>{`Female: ${formatRange(ageRangeFemale)}`}</p>);
    }

    return null;
  }

  render() {
    const {
      dsp_links,
      campaign: {
        status,
        campaign_url: campaignUrl,
        notes,
        flight,
        campaign_type,
        demand_side_platform,
      },
    } = this.props;
    const { selectedTab } = this.state;
    let dspArray = [];
    let dspString = '';
    if (typeof demand_side_platform === 'string') {
      dspString = demand_side_platform;
    } else {
      dspArray = JSON.parse(demand_side_platform);
    }
    return (
      <div className="campaign-details">
        <div className="row">
          <div className="col grid-item">
            <div className="details-card status">
              <h6>Status</h6>
              <div style={{ display: 'inline-flex' }}>
                <span className={`dot ${statusColor[status]}`} />
                <p>{statusLabel[status]}</p>
              </div>
            </div>
          </div>
          <div className="col grid-item">
            <div className="details-card">
              <h6>Destination URL</h6>
              <div style={{ display: 'inline-flex' }}>
                <p>{campaignUrl}</p>
              </div>
            </div>
          </div>
          <div className="col grid-item">
            <div className="details-card">
              <h6>Flight</h6>
              <div style={{ display: 'inline-flex' }}>
                <p>{flight}</p>
              </div>
            </div>
          </div>
          <div className="col grid-item">
            <div className="details-card">
              <h6>Campaign Type</h6>
              <div style={{ display: 'inline-flex' }}>
                <p>{this.props.options.find((item) => item.value === campaign_type).label}</p>
              </div>
            </div>
          </div>
          { campaign_type === 'trade_side_platform_auto_setup' && (
          <div className="col grid-item">
            <div className="details-card">
              <h6>DSP</h6>
              { dspArray && dspArray.length > 1 ? (
                <div>
                  {
                  dspArray.filter((dsp) => dsp !== '').map((dsp) => (
                    <div><a key={dsp} target="_blank" href={dsp_links[dsp]} rel="noreferrer">{dsp}</a></div>
                  ))
                }
                </div>
              ) : (
                <div>
                  <div><a key={dspString} target="_blank" href={dsp_links[dspString]} rel="noreferrer">{dspString}</a></div>

                </div>
              )}
            </div>
          </div>
          )}
        </div>
        <div className="row">
          <div className="col-12 grid-item">
            <div className="details-card more-details">
              <Tabs
                tabs={this.tabs}
                onClick={(event) => this.setSelectedTab(event)}
                selectedTab={selectedTab}
                className="tabs-component"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CampaignDetailsComponent.propTypes = {
  campaign: PropTypes.shape({
    affinities: PropTypes.objectOf(PropTypes.object),
    age_range_female: PropTypes.arrayOf(PropTypes.number),
    age_range_male: PropTypes.arrayOf(PropTypes.number),
    budget: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    campaign_url: PropTypes.string,
    notes: PropTypes.string,
    campaign_type: PropTypes.string,
    conversion_rate: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    geography: PropTypes.arrayOf(PropTypes.string),
    goal: PropTypes.string,
    household_income: PropTypes.arrayOf(PropTypes.number),
    kpi: PropTypes.string,
    status: PropTypes.string,
    target_cpa: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    target_roas: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  }).isRequired,
  field_mapping: PropTypes.object,
};
