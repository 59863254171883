import React from 'react';
import AdSpendCalculator from '../../components/AdSpendCalculator';

const CampaignIndexComponent = () => (
  <div className="row">
    <div className="col-12">
      <div className="card card-v2">
        <div className="card-body">
          <AdSpendCalculator />
        </div>
      </div>
    </div>
  </div>
);

export default CampaignIndexComponent;
