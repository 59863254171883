import React from 'react';
import moment from 'moment';
import { Table } from 'react-bootstrap';

const ContainersTable = ({
  containers, setIsContainerManager,
  setIsPixelManager,
  setActiveContainer,
}) => {
  const onContainerClick = (container) => {
    setActiveContainer(container);
    setIsContainerManager(false);
    setIsPixelManager(true);
  };

  return (
    <Table responsive className="containerTable" striped bordered hover>
      <thead>
        <tr className="containerTableHead">
          <th>Name</th>
          <th>Status</th>
          <th>Container ID</th>
          <th>Last Edited</th>
        </tr>
      </thead>
      <tbody>
        {containers && containers.map((container) => (
          <tr key={container.id} onClick={() => onContainerClick(container)}>
            <td>
              <div className="containerName">
                {container.name}
              </div>
            </td>
            <td>
              {container.status ? (
                <div className="green-dot" />
              ) : (
                <div className="red-dot" />
              )}
            </td>
            <td>
              ORC-
              {container.id}
            </td>
            <td>{moment(container.updated_at).format('LLL')}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ContainersTable;
