import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import InputLabel from './InputLabel';

export default class SelectInputWithTooltips extends Component {
  render() {
    const {
      name,
      label,
      options,
      value,
      tooltip,
      handleChange,
      multiSelect,
    } = this.props;

    const updatedOptions = options.map((option) => ({
      label: (
        <>
          <ReactTooltip
            role="tooltip"
            className="cb-select-tooltip"
            type="info"
            place="right"
            effect="solid"
            id={option?.value}
          />
          <div
            data-for={option?.value}
            data-tip={option?.tooltip}
            data-event="mouseover focus"
            data-event-off="mouseout blur"
            aria-label="option"
            className="select-option"
            ref={this.selectOption}
            role="button"
            tabIndex="0"
          >
            {option?.label}
          </div>
        </>
      ),
      tooltip: option.tooltip,
      value: option.value,
    }));

    return (
      <Form.Group controlId={name} title={tooltip}>
        <InputLabel label={label} tooltip={tooltip} />
        <Select
          className="multiSelectV2"
          classNamePrefix="multiSelectV2"
          options={updatedOptions}
          isMulti={multiSelect}
          name={name}
          onChange={handleChange}
          value={value}
        />
      </Form.Group>
    );
  }
}

SelectInputWithTooltips.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  tooltip: PropTypes.string,
  multiSelect: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
};
