const createPixelAction = (pendingPixel,
  pendingTrigger,
  container,
  token,
  advertiser) => new Promise((resolve, reject) => {
  const body = JSON.stringify({
    authenticity_token: token,
    pixel: {
      code: pendingPixel.code,
      container_id: pendingPixel.container_id,
      name: pendingPixel.name,
      triggers: JSON.parse(pendingTrigger.json),
    },
  });

  const requestOptions = {
    body,
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
  };

  fetch(`/vendors/${advertiser.id}/containers/${container.id}/pixels`, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      if (data.status) {
        resolve(data);
      } else {
        throw new Error(data.message || 'An error occurred, please contact support.');
      }
    })
    .catch((error) => {
      reject(error);
    });
});

const updatePixelAction = (pixel,
  container,
  token,
  advertiser) => new Promise((resolve, reject) => {
  const body = JSON.stringify({
    authenticity_token: token,
    pixel: {
      code: pixel?.code,
      container_id: pixel?.container_id,
      name: pixel?.name,
    },
  });

  const requestOptions = {
    body,
    headers: { 'Content-Type': 'application/json' },
    method: 'PUT',
  };

  fetch(`/vendors/${advertiser.id}/containers/${container.id}/pixels/${pixel.id}`, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      if (data.status) {
        resolve(data);
      } else {
        throw new Error(data.message || 'An error occurred, please contact support.');
      }
    })
    .catch((error) => {
      reject(error);
    });
});

const updateTriggerAction = (pixel,
  container,
  token,
  advertiser) => new Promise((resolve, reject) => {
  const body = JSON.stringify({
    authenticity_token: token,
    pixel: {
      triggers: pixel?.json ? JSON.parse(pixel?.json) : {},
    },
  });

  const requestOptions = {
    body,
    headers: { 'Content-Type': 'application/json' },
    method: 'PUT',
  };

  fetch(`/vendors/${advertiser.id}/containers/${container.id}/pixels/${pixel.id}`, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      if (data.status) {
        resolve(data);
      } else {
        throw new Error(data.message || 'An error occurred, please contact support.');
      }
    })
    .catch((error) => {
      reject(error);
    });
});

const getPixelsForContainer = (advertiser, container) => new Promise((resolve, reject) => {
  const method = 'GET';
  const requestOptions = {
    headers: { 'Content-Type': 'application/json' },
    method,
  };

  fetch(`/vendors/${advertiser.id}/containers/${container.id}/pixels`, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      if (data.status) {
        resolve(data.result);
      } else {
        throw new Error(data.message || 'An error occurred, please contact support.');
      }
    })
    .catch((error) => {
      reject(error);
    });
});

const deletePixelAction = (pixel,
  container,
  token,
  advertiser) => new Promise((resolve, reject) => {
  const body = JSON.stringify({
    authenticity_token: token,
  });

  const requestOptions = {
    body,
    headers: { 'Content-Type': 'application/json' },
    method: 'DELETE',
  };

  fetch(`/vendors/${advertiser.id}/containers/${container.id}/pixels/${pixel.id}`, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      if (data.status) {
        resolve(data);
      } else {
        throw new Error(data.message || 'An error occurred, please contact support.');
      }
    })
    .catch((error) => {
      reject(error);
    });
});

export {
  createPixelAction,
  getPixelsForContainer,
  updatePixelAction,
  updateTriggerAction,
  deletePixelAction,
};
